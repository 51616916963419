import a from "../assets/images/1.jpg";
import b from "../assets/images/2.jpg";
import c from "../assets/images/3.jpg";
import d from "../assets/images/4.jpg";
import e from "../assets/images/5.jpg";
import f from "../assets/images/6.jpg";
import g from "../assets/images/7.jpg";
import h from "../assets/images/8.jpg";
import i from "../assets/images/9.jpg";
import j from "../assets/images/10.jpg";
import k from "../assets/images/11.jpg";
import l from "../assets/images/12.jpg";
import m from "../assets/images/13.jpg";
import n from "../assets/images/14.jpg";
import o from "../assets/images/15.jpg";
import p from "../assets/images/16.jpg";
import q from "../assets/images/17.jpg";
import r from "../assets/images/18.jpg";
import s from "../assets/images/19.jpg";
import t from "../assets/images/20.jpg";
import u from "../assets/images/21.jpg";
import v from "../assets/images/22.jpg";
import w from "../assets/images/23.jpg";
import x from "../assets/images/24.jpg";
import y from "../assets/images/25.jpg";
import z from "../assets/images/26.jpg";
import aa from "../assets/images/27.jpg";
import ab from "../assets/images/28.jpg";
import ac from "../assets/images/29.jpg";
import ad from "../assets/images/30.jpg";

const Images = [
	a,
	b,
	c,
	d,
	e,
	f,
	g,
	h,
	i,
	j,
	k,
	l,
	m,
	n,
	o,
	p,
	q,
	r,
	s,
	t,
	u,
	v,
	w,
	x,
	y,
	z,
	aa,
	ab,
	ac,
	ad,
];

export default Images;